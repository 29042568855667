import React from "react";
import { LineChart, Line, XAxis, YAxis, ScatterChart, Scatter, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function RideDetailsGraph({data}){


return (

    <ResponsiveContainer width = '95%' height = {500} >
     <LineChart
          data={data.data.open_values}
        >
          
          <XAxis dataKey="time" />
          <YAxis  type="number" dataKey="value"  domain={[0,"dataMax+5"]}/>
          <Tooltip />
          <Legend />
          <Line   strokeWidth={3} unit=" minutes" type="monotone" dataKey="value" name="Queue Time" fill="#007c4a" stroke="#007c4a" />
          <Line strokeWidth={3}  type="monotone" dataKey="valueClosed" name="Ride Down" stroke="#793200" />
        </LineChart>
  </ResponsiveContainer>
)



}