import React from "react";
import QueueTimeTableDesktop from "./QueueTimesTableDesktop";


export default function QueueTimeDesktopView({filter, data, groups }){




return (
    <div className="desktop-queue-tables">
{ groups.map(function(group, i){
 return <QueueTimeTableDesktop data={data} filter={group} key={i} />

    }



)
}</div>)
}


