import React, {useState, useEffect} from 'react'
import axios from 'axios'
import '../styles/StartPage.css'
import MobileHeader from '../MobileHeader'
import HeadLine from './Headline'
import ParksList from './ParksList'
import axiosInstance from '../Cache/axiosInstance'
import PageLoader from '../PageLoader'
import CookieWarning from '../CookieWarning.js/CookieWarning'
import { fetchWithCache } from '../Cache/axiosInstance'
export default function StartPage(){



    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true);

    useEffect(() => {

   


        let isMounted = true;
  
        const fetchData = async () => {
          try {
            const { fromCache, data, backgroundFetch } = await fetchWithCache(
              `https://queuetimehub.com/v3/api/get-parks.php`
            );
    
            if (isMounted) {
              setData(data);
              
              setLoading(false)
    
              // Optional: Handle background fetch results if needed
              if (fromCache) {
                backgroundFetch?.then((updatedData) => {
                  if (isMounted && updatedData) {
                    setData(updatedData);
                    
                    setLoading(false)
                  }
                });
              }
            }
          } catch (error) {
            if (isMounted) {
              console.error('There was an error fetching the data!', error);
              setLoading(false);
            }
          }
        };
    
        fetchData();
    
        return () => {
          isMounted = false; // Cleanup to avoid memory leaks
        };
      }, []);


      if (loading) {
        return <PageLoader />;
      }





      



return (
    <>
<MobileHeader />
<ParksList data={data.parks} />
<CookieWarning />
</>
)



}