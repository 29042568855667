import React from "react";


export default function RideDetailsHistoryControlButton({text, click}){


return (



    <button onClick={()=>click()} className="ride-details-history-button">{text}
    </button>
)


}