import React from "react"

export default function ThemeParkWikiFooter(){


return (


    <div className="theme-park-wiki-footer">

<div className="theme-park-wiki-footer-inner">
    <div className="theme-park-wiki-footer-text">Powered by ThemeParks.wiki</div>
    <div className="theme-parks-wiki-footer-link"><a href="https://www.themeparks.wiki/">ThemeParks.wiki</a></div>
</div>

    </div>
)


}