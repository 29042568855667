import React from 'react'
import ListItemItem from './ListItemItem'
import ListItemStatQueue from './ListItemStatQueue'



export default function ListItem({data, isPast}){





return (
    <>


{ data.status != "open" && isPast == false? <div className='list list-current-downtime-data'>
    <div className='list-item-title'>Current Downtime Information</div>
    <div className='list-item-container'>
<ListItemItem title={"Current downtime duration"} data={data.time_since_down}/>
<ListItemItem title={"Queue at Downtime"} data={data.downtime_text}/>
 </div>
    </div> : null} 


{isPast == false ? <div className='list'>
    <div className='list-item-title'>Basic Info</div>
    <div className='list-item-container'>
<ListItemItem title={"Opening Times"} data={data.opening_times_frontend}/>
<ListItemItem title={"Last Updated"} data={data.last_updated}/>
 </div>
    </div> : null }


<div className='list'>
    <div className='list-item-title'>Queue Statistics</div>
    <div className='list-item-container'>
{isPast == false ? <ListItemStatQueue title={"Average 7day Queue"} stat={data.average_sevenday_queue} color={data.average_sevenday_queue_color} /> : null}
{data.longest_queue_time_today !== null ? <ListItemStatQueue title={"Longest Queue Today"} stat={data.longest_queue_time_today} color={data.longest_queue_time_today_color} /> : null } 
{isPast == false ? <ListItemStatQueue title={"Longest Recorded Queue"} stat={data.longest_queue_time_ever} color={data.longest_queue_time_ever_color} /> : null} 
    </div>
    </div>


    {data.delay_count > 0 ? <div className='list'>
    <div className='list-item-title'>Downtime Information</div>
    <div className='list-item-container'>
<ListItemItem title={"Downtime Periods Today"} data={data.delay_count}/>
<ListItemItem title={"Average Downtime Delay"} data={data.downtime_average + " minutes"}/>
 </div>
    </div> : null}
    </>
)


}