import React from "react";  


export default function PageLoader(){





return (


<div className={"page-loader"}>

<div className="loading-pop-up-container">

<div className="loading-pop-up-inner">

<div className="loading-pop-up-loader"><span className="loader"></span></div>
<div className="loadiing-pop-up-text">Please Wait</div>

</div>

</div>


</div>

)


}