import React from "react";


export default function RideDetailsDowntimeTableItem({data}){


return (
    

<tr className="ride-details-downtime-table-item">


    <td className="ride-details-downtime-table-timedown">{data.time_down}</td>
    <td className="ride-details-downtime-table-timeup">{data.time_up}</td>
    <td className={"ride-details-downtime-table-queuedown "+ data.queue_at_downtime_color}>{data.queue_at_downtime} mins</td>
    <td className={"ride-details-downtime-table-queueup " + data.queue_at_uptime_color}>{data.queue_at_uptime} mins</td>
    <td className="ride-details-downtime-table-duration">{data.duration}</td>


</tr>


)


}