import React from "react";


export default function RideDetailsHeader({data}){

return (

    <div className="ride-details-header">

<div className="ride-details-header-title">{data.display_name}</div>
{ data.status == "open" ? 
<div className={"ride-details-header-status queue-" + data.color}>{data.time}<span className="ride-details-header-queue-minutes"> minutes</span></div> :
<div className="ride-details-header-status">{data.status}</div>
}

    </div>
)



}