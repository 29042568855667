import React, {useState} from "react";
import { NavLink } from "react-router-dom";


export default function ParksListItem({data}){

    const [showWarning, updateshowwarning]= useState(false) 



    const handleWarningShow = () => {
if (showWarning == true){

    updateshowwarning(false)
} else {


        updateshowwarning(true)
        }        }

    return (
<>

        <div className="parks-list-item">
    
    <div className="parks-list-item-inner">
   { data.is_alert == "1" ? <div className="parks-list-item-warning" onClick={handleWarningShow}>⚠️</div> : null }
   { data.is_alert == "1" ?  <div className="parks-list-item-title" >{data.display_name}</div> : null }
   { data.is_alert == "0" ?  <div className="parks-list-item-title-nowarning" >{data.display_name}</div> : null }
  <div className={"parks-list-item-status " + data.hours.state.class}>{data.hours.state.state}</div> 
    <NavLink to={'queue-times/' + data.id}>
    <div className="parks-list-item-end">➡️</div>
    </NavLink>
    </div>

    
    </div>

    { 
   
   showWarning ? <div className="parks-list-item-warning-dropdown">
    <div className="parks-list-item-warning-title">{data.alert_content}</div>
   </div>

: null }

</>


    )
}