import React, {useState} from "react";
import QueueTimesTableItem from "./QueueTimesTableItem";
import { useParams } from "react-router-dom";


export default function QueueTimeTableDesktop({data, filter}){


return (
    <div className="queue-times-table desktop-queue-table">
<div className="queue-times-table-desktop-group-name">{filter}</div>
{data.map(function(ride, i){
return (

ride.table_group === filter ? 
  <QueueTimesTableItem key={i} data={ride} /> 
: null 

)
})}






    </div>
)



}