import React from "react";
import '../styles/MobileHeader.css'

export default function MobileHeader(){


return (
<div className="mobile-header">
<div className="mobile-header-title">Queue Time Hub</div>
</div>
)

}