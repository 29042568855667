import React, {useState} from "react";
import '../styles/StartPage.css'
import ParksListItem from "./ParksListItem";



export default function ParksList({data}){

    
         
return (
<div className="parks-list-container">
<div className="parks-list-title">Parks and Resorts</div>
<div className="parks-list-list-container">


{data.map(function(park, index){


return <ParksListItem data={park} />


})}
</div>

</div>

)


}