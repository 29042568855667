import React, { useState } from "react";
import QueueTimesWarning from "./QueueTimesWarning";
import { NavLink, useParams } from "react-router-dom";




export default function QueueTimesTableItem({data}){

const [showWarning, setShowWarning] = useState(false);
const {parkId} = useParams()

const handleWarningShow = () => {

if (showWarning == false){

setShowWarning(true)

} else {
    setShowWarning(false)
}





}

return (
<>

<div className="queue-times-table-item">

{ data.info_message != "NO INFO" && data.info_message != "" && data.info_message != null ? <div className="queue-times-table-item-warning" onClick={handleWarningShow}><i className="fa-solid fa-triangle-exclamation"></i></div> : null }
<NavLink className="queue-times-table-item-inner" to={"/ride-info/" + parkId + "/" + data.id}>
<div className="queue-times-item-table-text">
<div className="queue-times-table-item-title">{data.display_name}</div>
{ data.status != "open"  && data.is_out_of_hours == 0  ? <div className="queue-times-table-item-subtext">{data.downtime_string}</div> : null }
{data.status_message != "" ? <div className="queue-times-table-item-subtext">{data.status_message}</div> : null }
</div>
{data.status == "open" ?<div className="queue-times-table-item-queue-time">
    
    <div className={"queue-times-table-item-queue-time-time " + data.color}>{data.time} <span className="queue-time-minutes">mins</span></div>
   </div> : null }

{ data.status != "open" && data.queue_at_downtime != null ? <div className="queue-times-table-item-queue-time">
    
    <div className="queue-times-table-item-queue-time-closedwithdowntime">{data.queue_at_downtime} mins</div>
</div> : null }

{ data.status != "open" && data.queue_at_downtime == null ? <div className="queue-times-table-item-queue-time">
    
    <div className="queue-times-table-item-queue-time-time-closed">{data.status}</div>
</div> : null }
</NavLink>
</div>


{ 
   
   showWarning ? <QueueTimesWarning warning={data.info_message} /> : null }
   
</>

)



}