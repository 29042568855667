import React from "react";


export default function ListItemItem({data, title}) {

return (

    <div className="list-item-item">
        <div className="list-item-item-start">{title}</div>
        <div className="list-item-item-end">{data}</div>
    </div>
)




}