import React, {useState} from "react";
import RideDetailsHistoryControlButton from "./RideDetailsHistoryControlButton";
import CalendarPopup from "./CalendarPickerPopUp";

export default function RideHistoryControls({handleBack,setSelectedCalendarDate, selectedCalendarDate, handleToday, handleCalendarDate}){


    const [isOpen, setIsOpen] = useState(false)

    


return (


    <div className="ride-details-history-controls-container">
<RideDetailsHistoryControlButton click={handleBack} text="-1 Day" />
<RideDetailsHistoryControlButton click={handleToday} text="Today" />
<RideDetailsHistoryControlButton click={() => setIsOpen(true)} text="Jump to Date" />


{isOpen ? <CalendarPopup setSelectedCalendarDate={setSelectedCalendarDate} selectedCalendarDate={selectedCalendarDate} setIsOpen={setIsOpen} isOpen={isOpen} onDateSelect={handleCalendarDate} /> : null }
    </div>
)



}