import React from "react";



export default function QueueTimesWarning({warning}){

return (
    <div className="queue-table-item-warning-dropdown">
    <div className="queue-item-warning-title">{warning}</div>
   </div>
)
}