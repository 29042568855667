// Install the react-datepicker library before using this code
// npm install react-datepicker
import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CalendarPopup = ({ onDateSelect, selectedCalendarDate, setSelectedCalendarDate, isOpen, setIsOpen }) => {
  const [selectedDate, setSelectedDate] = useState(selectedCalendarDate);
  ;

  const handleDateChange = (date) => {
    setSelectedCalendarDate(date);
    setIsOpen(false); // Close the popup after selecting a date
    if (onDateSelect) {
      onDateSelect(formatDate(date));
    }
  };

  const formatDate = (date) => {
    if (!date) return '';
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  return (
    <div className='calendar-date-picker'>
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <button onClick={() => setIsOpen(!isOpen)}>
        {selectedDate ? formatDate(selectedDate) : 'Select Date'}
      </button>

      {isOpen && (
        <div style={{ position: 'relative', zIndex: 1000 }}>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
          />
        </div>
      )}
    </div>
    </div>
  );
};

export default CalendarPopup;

// Example usage:
// import CalendarPopup from './CalendarPopup';
// const App = () => {
//   const handleDateSelect = (dateString) => {
//     console.log('Selected date:', dateString);
//   };
//   return <CalendarPopup onDateSelect={handleDateSelect} />;
// };
