import React, {useEffect, useState} from "react";
import QueueGroupItem from "./QueueGroupItem";
import '../QueueTimes/QueueTimes.css'
import axios from "axios";

export default function QueueGroups({groups, filter, handleFilter}){


return (
groups !== null ? 
    <div className="queue-group-container">

      { groups.map(function(group, i){


  return <QueueGroupItem key={i} filter={filter} handleFilter={handleFilter} group={group}/>


      } 
    )
    }
    </div>

 
:null 


)




}