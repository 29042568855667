import React from "react"

export default function RideTimesFooter(){


return (


    <div className="ride-times-footer">

<div className="ride-times-footer-inner">
    <div className="ride-times-footer-text">View Alton Towers Queue Times in legacy format</div>
    <div className="ride-times-footer-link"><a href="https://www.ride-times.co.uk/">ride-times.co.uk</a></div>
</div>

    </div>
)


}