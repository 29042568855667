import logo from './logo.svg';
import './App.css';
import {
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import StartPage from './StartPage';
import QueueTimes from './QueueTimes/QueueTimes';
import RideDetails from './RideDetails/RideDetails';

function App() {

  const location = useLocation();



  return (
    <div className="App">
     <Routes key={location.pathname} location={location}>

<Route path="/" element={<StartPage />} />
<Route path="/queue-times/:parkId" element={<QueueTimes />} />
<Route path="/ride-info/:parkId/:rideId" element={<RideDetails />} />
</Routes>
    </div>
  );
}

export default App;
