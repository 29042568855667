import React, {useState, useEffect} from "react";
import MobileHeader from "../MobileHeader";
import QueueGroups from "./QueueGroups";
import QueueTimeTable from "./QueueTimeTable";
import axios from "axios";
import axiosInstance from '../Cache/axiosInstance'
import { useParams } from "react-router-dom";
import ParkInfoQueueTimes from "./ParkInfoQueueTimes";
import StatsBar from "./StatsBar";
import PageLoader from "../PageLoader";
import QueueTimeDesktopView from "./QueueTimesDesktopView";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import { fetchWithCache } from "../Cache/axiosInstance";
import ThemeParkWikiFooter from "./ThemeParkWikiFooter";
import RideTimesFooter from "./RideTimesFooter";


export default function QueueTimes({}){



  const { parkId } = useParams()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true);
const [isatr, setIsatr] = useState(false)



    const [filter, setFilter] = useState("");

    const handleFilter = (category) => {
        setFilter(category)
       };
   


        const [width, setWidth] = useState(window.innerWidth);
        const handleWindowSizeChange = () => {
                setWidth(window.innerWidth);
        }
    
        useEffect(() => {
            window.addEventListener('resize', handleWindowSizeChange);
            return () => {
                window.removeEventListener('resize', handleWindowSizeChange);
            }
        }, []);
    
    



        useEffect(() => {

          if (parkId == 1){
            setIsatr(true)
          }
          let isMounted = true;
    
          const fetchData = async () => {
            try {
              const { fromCache, data, backgroundFetch } = await fetchWithCache(
                `https://queuetimehub.com/v3/api/get-queue-times.php?id=${parkId}`
              );
      
              if (isMounted) {
                setData(data);
                handleFilter(data.groups[0])
                setLoading(false)
      
                // Optional: Handle background fetch results if needed
                if (fromCache) {
                  backgroundFetch?.then((updatedData) => {
                    if (isMounted && updatedData) {
                      setData(updatedData);
                      handleFilter(updatedData.groups[0])
                      setLoading(false)
                    }
                  });
                }
              }
            } catch (error) {
              if (isMounted) {
                console.error('There was an error fetching the data!', error);
                setLoading(false);
              }
            }
          };
      
          fetchData();
      
          return () => {
            isMounted = false; // Cleanup to avoid memory leaks
          };
        }, [parkId]);


      if (loading) {
        return <PageLoader />;
      }

      
return (
    <>
    { width < 768 ?
    <>
<MobileHeader />
<ParkInfoQueueTimes data={data} />
<QueueGroups filter={filter} handleFilter={handleFilter} groups ={data.groups} />
<QueueTimeTable filter = {filter} data={data.data}/>
{data.is_themeparkwiki == 1 ? <ThemeParkWikiFooter/> : null}
{isatr == 1 ? <RideTimesFooter/> : null}
</>
: <>
<DesktopHeader isatr={isatr} />
<ParkInfoQueueTimes data={data} />
               <QueueTimeDesktopView filter = {filter} data={data.data}  groups ={data.groups}  />
               {data.is_themeparkwiki == 1 ? <ThemeParkWikiFooter/> : null}
               {isatr == 1 ? <RideTimesFooter/> : null}
               </>
    }
           
</>
)

}