import React from "react";


export default function ListItemStatQueue({stat, color, title}) {

return (

    <div className="list-item-item">
        <div className="list-item-item-start">{title}</div>
        <div className={"list-item-item-end " + color}>{stat} minutes</div>
    </div>
)




}