import React from "react";
import Guage from "./Guage";
import GaugeComponent from "react-gauge-component";


export default function ParkInfoQueueTimes({data}){




return (


    <div className="park-info-container">
        <div className="park-info-left">
<div className="park-info-title">{data.name}</div>
<div className="park-info-current-time">Local Time: {data.hours.current_time}</div>

{data.hours.is_special_event == "1" ?




<div className="park-info-special-event">
    <div className="park-info-special-event-name">{data.hours.event_icon} {data.hours.event_name}</div> 
    </div> : null }
<div className={"park-info-status " + data.hours.state.class}>{data.hours.state.state}</div>

{data.hours.is_open == "1"? <div className="park-info-hours">{data.hours.open_time} - {data.hours.close_time}</div> : null }
{data.hours.has_special_hours == "1"? <div className="park-info-special-hours">{data.hours.special_hours_name}: {data.hours.special_open_time} - {data.hours.special_close_time}</div> : null }
    
    
    
    </div>


<div className="park-info-right">

<Guage data={data.availability} />
<div className="availability-title">Availability</div>

</div>

    </div>
)



}