import React from "react";
import { NavLink } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function QueueGroupItem({group, filter, handleFilter}){

   const { parkId } = useParams()

return (
   
filter === group ? 

<div onClick={() => handleFilter(group)} className="queue-group-item selected-item">
   <div className="queue-group-item-title">{group}</div>
</div> 
:  
<div onClick={() => handleFilter(group)} className="queue-group-item">
   <div className="queue-group-item-title">{group}</div>
</div>


)



}