import React, {useState} from "react";
import QueueTimesTableItem from "./QueueTimesTableItem";
import { useParams } from "react-router-dom";


export default function QueueTimeTable({data, filter}){


       const isInArray = (element, index, array) => {
        if(element.table_group === filter){
          return true

        }
      }
    
    

return (
    <div className="queue-times-table">

{data.map(function(ride, i){
return (

ride.table_group === filter ? 
  <QueueTimesTableItem key={i} data={ride} /> 
: null 

)
})}






    </div>
)



}