import React from "react";
import RideDetailsDowntimeTableItem from "./RideDetailsDowntimeTableItem";


export default function ListItemDowntimeHistory({data}){



return (

<div className="ride-details-downtime-history">

<div className="ride-details-downtime-history-title">Downtime History</div>

    <table className="ride-details-downtime-history-table">
<thead>
<th className="ride-details-downtime-history-table-header">Time Down</th>
<th className="ride-details-downtime-history-table-header">Time Up</th>
<th className="ride-details-downtime-history-table-header">Queue Down</th>
<th className="ride-details-downtime-history-table-header">Queue Up</th>
<th className="ride-details-downtime-history-table-header">Duration</th>
</thead>


{data.map(function(downtime, i){

return <RideDetailsDowntimeTableItem data={downtime} key={i} />


})
}
</table>

</div>


)



}