import React, {useState, useEffect} from "react";


const CookieWarning = () => {
    const [isDismissed, setIsDismissed] = useState(false);
  
    useEffect(() => {
      // Check local storage to see if the popup has been dismissed
      const dismissed = localStorage.getItem("cookieWarningDismissed");
      if (dismissed === "true") {
        setIsDismissed(true);
      }
    }, []);
  
    const handleDismiss = () => {
      // Set the dismissed state in local storage and update the component state
      localStorage.setItem("cookieWarningDismissed", "true");
      setIsDismissed(true);
    };
  
    if (isDismissed) return null; // Don't render the popup if it's dismissed
  
    return (
      <div className="cookie-warning">
        <p className="cookie-warning-inner">
          We use cookies to for core functionality and to improve your experience on our site. By continuing to
          use our site, you accept our use of cookies.
        </p>
        <button className="cookie-dismiss-button" onClick={handleDismiss}>
          Dismiss
        </button>
      </div>
    );
  };


export default CookieWarning