import React, {date, useEffect, useState} from "react";
import MobileHeader from "../MobileHeader";
import ListItem from "./ListItem";
import './RideDetails.css'
import { useParams } from "react-router-dom";
import axios from "axios";
import RideDetailsHeader from "./RideDetailsHeader";
import ListItemDowntimeHistory from "./ListItemDowntimeHistory";
import RideDetailsHistoryControls from './RideDetailsHistoryControls'
import RideDetailsGraph from "./RideDetailsGraph";
import axiosInstance from "../Cache/axiosInstance";
import PageLoader from "../PageLoader";
export default function RideDetails({}){

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isPast, setIsPast] = useState(false);
    const { parkId, rideId } = useParams()
    const [data, setData] = useState(null)
    const [graphData , setGraphData] = useState(null)
    const [loading, setLoading] = useState(true);
    const [selectedCalendarDate, setSelectedCalendarDate] = useState("")
const [graphLoading, setGraphLoading] = useState(true);
    useEffect(() => {
        // Make an Axios request to fetch the text file from the server
        axios.get('https://queuetimehub.com/v3/api/get-ride-info.php?id=' + parkId + "&rideid=" + rideId)
          .then(response => {

            setData(response);
            setLoading(false);   
            //console.log(data)
          })
          .catch(error => {
            console.error('There was an error fetching the file!', error);
            setLoading(false);   
          });



          axios.get('https://queuetimehub.com/v3/api/get-graphs.php?id=' + parkId + "&rideid=" + rideId)
          .then(response => {

            setGraphData(response);
            setGraphLoading(false);   
            //console.log(data)
          })
          .catch(error => {
            console.error('There was an error fetching the file!', error);
            setGraphLoading(false);   
          });
      }, []);


      if (loading) {
        return <PageLoader />;;
      }


const handleToday = () => {

setLoading(true)
setGraphLoading(true)
setIsPast(false)
 // Make an Axios request to fetch the text file from the server
 axiosInstance.get('https://queuetimehub.com/v3/api/get-ride-info.php?id=' + parkId + "&rideid=" + rideId)
 .then(response => {

   setData(response);
   setLoading(false);   
   //console.log(data)
 })
 .catch(error => {
   console.error('There was an error fetching the file!', error);
   setLoading(false);   
 });



 axiosInstance.get('https://queuetimehub.com/v3/api/get-graphs.php?id=' + parkId + "&rideid=" + rideId)
 .then(response => {

   setGraphData(response);
   setGraphLoading(false);   
   //console.log(data)
 })
 .catch(error => {
   console.error('There was an error fetching the file!', error);
   setGraphLoading(false);   
 });
}

const handleCalendarDate = (dateString) => {
 
  setIsPast(true);
  setLoading(true)
  setGraphLoading(true)
  axios.get('https://queuetimehub.com/v3/api/get-ride-info-date.php?id=' + parkId + "&rideid=" + rideId + "&date=" + dateString)
  .then(response => {

    setData(response);
    setLoading(false);   
    //console.log(data)
  })
  .catch(error => {
    console.error('There was an error fetching the file!', error);
    setLoading(false);   
  });



  axios.get('https://queuetimehub.com/v3/api/get-graphs.php?id=' + parkId + "&rideid=" + rideId  + "&date=" + dateString)
  .then(response => {

    setGraphData(response);
    setGraphLoading(false);   
    //console.log(data)
  })
  .catch(error => {
    console.error('There was an error fetching the file!', error);
    setGraphLoading(false);   
  });


}





const handleBack = () => {
setIsPast(true);
setLoading(true)
setGraphLoading(true)
  const currentDate = selectedDate

  // Subtract one day from the current date
  currentDate.setDate(currentDate.getDate() - 1);

  // Get the year, month, and day
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');  // Month is zero-indexed
  const day = currentDate.getDate().toString().padStart(2, '0');

  // Format the date as yyyy-mm-dd
  const formattedDate = `${year}-${month}-${day}`;


  axios.get('https://queuetimehub.com/v3/api/get-ride-info-date.php?id=' + parkId + "&rideid=" + rideId + "&date=" + formattedDate)
  .then(response => {

    setData(response);
    setLoading(false);   
    //console.log(data)
  })
  .catch(error => {
    console.error('There was an error fetching the file!', error);
    setLoading(false);   
  });



  axios.get('https://queuetimehub.com/v3/api/get-graphs.php?id=' + parkId + "&rideid=" + rideId  + "&date=" + formattedDate)
  .then(response => {

    setGraphData(response);
    setGraphLoading(false);   
    //console.log(data)
  })
  .catch(error => {
    console.error('There was an error fetching the file!', error);
    setGraphLoading(false);   
  });

}

return (
<>
    <MobileHeader />
    <RideDetailsHeader data={data.data[0]} />
    <RideDetailsHistoryControls setSelectedCalendarDate={setSelectedCalendarDate} selectedCalendarDate={selectedCalendarDate} handleCalendarDate={handleCalendarDate} isPast={isPast} handleToday={handleToday} handleBack={handleBack}/>
    <ListItem isPast={isPast}  data={data.data[0]}/>
     {data.data[0].downtime_periods.length == 0 ? null : <ListItemDowntimeHistory data={data.data[0].downtime_periods} /> }
     {graphLoading == false ? <RideDetailsGraph data={graphData} /> : <span>Graph is loading</span>}
</>
)


}