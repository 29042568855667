import axios from 'axios';
import { getCache, setCache } from './cache';

const axiosInstance = axios.create();

// Cache Time-To-Live (e.g., 5 minutes)
const CACHE_TTL = 5 * 60 * 1000;

/**
 * Fetch data with caching and automatic updates.
 * @param {string} url - The request URL.
 * @param {object} options - Axios request options.
 * @returns {Promise<{ fromCache: boolean, data: any }>} Cached or fetched data.
 */
export const fetchWithCache = async (url, options = {}) => {
  const cachedData = getCache(url);

  if (cachedData) {
    // Return cached data immediately and fetch updated data in the background
    const backgroundFetch = axiosInstance
      .get(url, options)
      .then((response) => {
        const freshData = response.data;

        // Update the cache if data has changed
        if (JSON.stringify(cachedData) !== JSON.stringify(freshData)) {
          setCache(url, freshData, CACHE_TTL);
        }

        return freshData;
      })
      .catch((error) => {
        console.error(`Background fetch failed for ${url}:`, error);
      });

    // Return cached data immediately
    return { fromCache: true, data: cachedData, backgroundFetch };
  }

  // If no cached data, perform a normal fetch
  const response = await axiosInstance.get(url, options);
  const freshData = response.data;

  // Cache the new data
  setCache(url, freshData, CACHE_TTL);

  return { fromCache: false, data: freshData };
};

export default axiosInstance;
