import React from "react";
import GaugeComponent from "react-gauge-component";

export default function Guage({data}){



    return (

<GaugeComponent
  type="radial"
  arc={{
    colorArray: [ '#FF2121', '#00FF15'],
    padding: 0.02,
    width: 0.2,
    subArcs:
      [
        { limit: 50 },
        { limit: 70 },
        { limit:  80}
      ]
  }}
  tickLabels={{

    hideMinMax: true
  }}
  pointer={{type: "needle", animationDelay: 0 }}
  value={data}
/>


    )
}