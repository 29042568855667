// localStorageCache.js

// Save data to localStorage with an expiration time
export const setCache = (key, data, ttl) => {
    const expires = Date.now() + ttl;
    const datenow = Date.now();
    const cacheData = { data, expires, datenow };
    localStorage.setItem(key, JSON.stringify(cacheData));
  };
  
  // Retrieve data from localStorage and check expiration
  export const getCache = (key) => {
    const cached = localStorage.getItem(key);
    if (!cached) return null;
  
    const { data, expires } = JSON.parse(cached);
    if (Date.now() > expires) {
      localStorage.removeItem(key); // Remove expired cache
      return null;
    }
    return data;
  };
  
  // Clear a specific cache entry
  export const clearCache = (key) => {
    localStorage.removeItem(key);
  };
  
  // Clear all cache (optional utility)
  export const clearAllCache = () => {
    localStorage.clear();
  };
  