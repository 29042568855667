import React from "react";
import './DesktopHeader.css'
import { Link } from "react-router-dom";
export default function DesktopHeader({isatr}){


return (


    <div className="desktop-header">

<div className="desktop-header-inner">
<div className="desktop-header-menu">
    <div className="desktop-header-menu-container">
        <div className="deksktop-header-menu-item"><Link to={{pathname: "/"}}>Parks</Link></div>
        <div className="menu-seperator"></div>
        <div className="deksktop-header-menu-item"><Link to={{pathname: "/queue-times/1/"}}>Alton Towers</Link></div>
        <div className="deksktop-header-menu-item"><Link to={{pathname: "/queue-times/23/"}}>Europa Park</Link></div>
        <div className="deksktop-header-menu-item"><Link to={{pathname: "/queue-times/45/"}}>Rulantica</Link></div>
    </div>
</div>
{ isatr ?
<div className="desktop-header-ride-times">
    <div className="desktop-header-ride-times-inner"><Link to={"https://ride-times.co.uk/"}>➡️ ride-times.co.uk</Link> </div>
</div> : null }

</div>


    </div>
)


}